import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';

import CampaignSummaryCard from '@/components/cards/CampaignSummaryCard';
import ErrorBoxCard from '@/components/cards/ErrorBoxCard';
import FacebookPostTemplate from '@/components/utility/social-media-templates/FacebookPostTemplate';
import InstaPostTemplate from '@/components/utility/social-media-templates/InstaPostTemplate';
import InstaStoryTemplate from '@/components/utility/social-media-templates/InstaStoryTemplate';
import { genderMap } from '@/constants/Advert';
import { UnhurdProductPrices } from '@/constants/ProductList';
import { capitalizeFirstLetter } from '@/formatters/TextTransformers';
import useSubscription from '@/hooks/account/useSubscription';
import { MetaAdsFormModel, MetaErrorValidationErrorModel } from '@/models/Meta';

import Summary from '../../generic-ads-steps/Summary';
import PaymentOptions from '../../PaymentOptions';

const MetaAdSummary = ({
  selectedFacebookMediaUrl,
  instagramUsername,
  errors,
}: {
  selectedFacebookMediaUrl?: string;
  instagramUsername?: string;
  errors?: MetaErrorValidationErrorModel[];
}) => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();

  const [tabValue, setTabValue] = useState<number>(0);

  const { getValues, watch } = useFormContext<MetaAdsFormModel>();
  const formPlatforms = watch('ad.platforms');
  const formLink = watch('ad.content.link');
  const formPrimaryText = watch('ad.content.primaryText');
  const formLinkHeadline = watch('ad.content.linkHeadline');
  const formLinkDescription = watch('ad.content.linkDescription');
  const formType = watch('ad.type');

  const socialMediaPreviewTabs = useMemo(() => {
    return [
      {
        platform: 'facebook',
        title: 'Facebook',
        name: 'BRAND.FACEBOOK',
        value: 0,
      },
      {
        platform: 'instagram',
        title: 'Instagram',
        name: 'COMMON.POST',
        value: 1,
      },
      {
        platform: 'instagram',
        title: 'Instagram',
        name: 'COMMON.STORY',
        value: 2,
      },
    ];
  }, []);

  const summaryData = useMemo(() => {
    const formAd = getValues('ad');
    const formPriceLocale = getValues('priceLocale');
    return [
      {
        name: 'CAMPAIGNS-PAGE.GOAL',
        value: formAd.goal ? formAd.goal.replace('-', ' ') : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.START-DATE',
        value: `${format(new Date(formAd.startTime), 'dd/MM/yy')} at ${format(new Date(formAd.startTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.END-DATE',
        value: `${format(new Date(formAd.endTime), 'dd/MM/yy')} at ${format(new Date(formAd.endTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.BUDGET',
        value: formAd.budget ? `${getSymbolFromCurrency(formPriceLocale)}${formAd.budget}` : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.AUDIENCE',
        value: `${t(genderMap[formAd.targeting.genders[0] ?? 0])}, ${formAd.targeting.ageMin}-${formAd.targeting.ageMax}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.LOCATIONS',
        value: Object.values(formAd.targeting.geoLocations)
          .flatMap((value) =>
            Array.isArray(value) ? value.map((item) => (typeof item === 'string' ? item : item.name)) : value
          )
          .join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.INTERESTS',
        value: formAd.targeting.audiences.map((audience) => audience.name).join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.PLATFORM',
        value: formAd.platforms.map((platform: string) => capitalizeFirstLetter(platform)).join(', '),
      },
    ];
  }, [getValues, t]);

  useEffect(() => {
    if (!formPlatforms.includes('Facebook')) {
      setTabValue(1);
    }
  }, [formPlatforms]);

  return (
    <Summary>
      <div className="text-left flex-w50p w100p-lg-down">
        <h4>{t('SOCIAL-ADS.PREVIEW-YOUR-AD')}</h4>
        <p className="text-faded mt8 mb16">{t('SOCIAL-ADS.PREVIEW-YOUR-AD-DESCRIPTION')}</p>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',

            marginBottom: '8px',
          }}
        >
          <Tabs value={tabValue} onChange={(_, value: number) => setTabValue(value)} aria-label="basic tabs example">
            {socialMediaPreviewTabs.map((tab) => {
              if (!formPlatforms.includes(tab.title)) return null;
              return (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={
                    <div className="d-flex gap8">
                      <img
                        className="summary-preview-icon"
                        src={`/images/logos/${tab.platform}-logo-white.svg`}
                        alt=""
                      />
                      <span className="mt-auto mb-auto">{t(tab.name)}</span>
                    </div>
                  }
                  data-testid={`insights-${tab.platform}`}
                />
              );
            })}
          </Tabs>
        </Box>
        <div className="mt20">
          {tabValue === 0 && (
            <FacebookPostTemplate
              mediaUrl={selectedFacebookMediaUrl ?? ''}
              mediaType={formType ?? 'image'}
              headline={formLinkHeadline}
              description={formLinkDescription}
              text={formPrimaryText}
              link={formLink}
              ctaText={'Listen Now'}
            />
          )}
          {tabValue === 1 && (
            <InstaPostTemplate
              pageName={instagramUsername}
              mediaUrl={selectedFacebookMediaUrl ?? ''}
              mediaType={formType ?? 'image'}
              caption={formPrimaryText}
              ctaText={'Listen Now'}
            />
          )}
          {tabValue === 2 && (
            <InstaStoryTemplate
              mediaUrl={selectedFacebookMediaUrl ?? ''}
              mediaType={formType ?? 'image'}
              caption={formPrimaryText}
              ctaText={'Listen Now'}
            />
          )}
        </div>
      </div>
      <div className="text-left flex-w50p w100p-lg-down">
        <h4>{t('SOCIAL-ADS.CHECK-THE-DETAILS')}</h4>
        <p className="text-faded mt8 mb16">{t('SOCIAL-ADS.CHECK-THE-DETAILS-DESCRIPTION')}</p>
        {summaryData && <CampaignSummaryCard summary={summaryData} />}
        {errors && (
          <div className="mt20">
            <ErrorBoxCard errors={errors} />
          </div>
        )}

        {!isSubscribed && (
          <div className="mt20">
            <PaymentOptions type="meta-ads" amount={UnhurdProductPrices.socialMediaBookingFee} />
          </div>
        )}
      </div>
    </Summary>
  );
};

export default MetaAdSummary;
